<template>
  <div class="page-container">
    <!-- IP封禁提示 -->
    <div class="ip-block-overlay" v-if="isBlocked">
      <div class="ip-block-content">
        <div class="block-icon">
          <font-awesome-icon :icon="['fas', 'ban']" />
        </div>
        <h2>访问受限</h2>
        <p>很抱歉，您所在的地区不支持访问本服务。</p>
        <p class="ip-info">IP: {{ userIP }} | 地区: {{ userRegion }}</p>
      </div>
    </div>

    <!-- 添加自定义提示框组件 -->
    <transition name="fade">
      <div class="custom-alert" v-if="showAlert">
        <div class="alert-content">
          <div class="alert-icon">
            <font-awesome-icon :icon="['fas', 'info-circle']" />
          </div>
          <div class="alert-message">{{ alertMessage }}</div>
          <div class="alert-close" @click="showAlert = false">
            <font-awesome-icon :icon="['fas', 'times']" />
          </div>
        </div>
      </div>
    </transition>
    <!-- 顶部导航 -->
    <nav class="nav-bar">
      <div class="nav-content">
      <div class="logo">
        <img src="../assets/vpn/logo.png" alt="logo" width="40" />
        <span>FlashGo</span>
      </div>
        <div class="nav-right">
          <div class="store-buttons">
          <img src="../assets/vpn/ios.png" alt="App Store" @click="handleDownload('ios')" />
          <img src="../assets/vpn/an.png" alt="Google Play" @click="handleDownload('android')" />
          <img src="../assets/vpn/win.png" alt="Windows" @click="handleDownload('windows')" />
          <img src="../assets/vpn/mac.png" alt="macOS" @click="handleDownload('macos')" />
        </div>
      </div>
      </div>
    </nav>

    <!-- 主内容区域 -->
    <main class="main-content">
      <!-- Hero区域 -->
      <section class="hero-section">
        <h1>不限制网站 懂你所需<br/>家宽IP落地 解锁风控</h1>
        <p class="subtitle">
          <span class="info-line">可用节点超 <span class="nodes-number">{{ displayNodes }}</span>+</span>
          <span class="info-line">可选家宽IP <span class="ips-number">{{ displayIPs }}</span>万+</span>
          <span class="info-line">延时低至<span class="ms-number">{{ displayMs }}</span>ms</span>
          <span class="info-line">带宽可达<span class="speed-number">{{ displaySpeed }}</span>Mbps</span>
        </p>
        <div class="download-options">
          <div class="store-buttons">

            <div class="download-item">
              <div class="download-btn play-store-btn" @click="handleDownload('android')">
                <div class="icon">
                  <font-awesome-icon :icon="['fab', 'android']" />
                </div>
                <div class="text-container">
                  <div class="small-text">立即下载</div>
                  <div class="large-text">Android APK</div>
                </div>
              </div>
              <div class="tip-text">华为手机需关闭[纯净模式]</div>
            </div>

            <div class="download-item">
              <div class="download-btn windows-btn" @click="handleDownload('windows')">
                <div class="icon">
                  <font-awesome-icon :icon="['fab', 'windows']" />
                </div>
                <div class="text-container">
                  <div class="small-text">立即下载</div>
                  <div class="large-text">Windows</div>
                </div>
              </div>
              <div class="tip-text">需要[以管理员身份运行]</div>
            </div>

            <div class="download-item">
              <div class="download-btn macos-btn" @click="handleDownload('macos')">
                <div class="icon">
                  <font-awesome-icon :icon="['fab', 'apple']" />
                </div>
                <div class="text-container">
                  <div class="small-text">立即下载</div>
                  <div class="large-text">macOS</div>
                </div>
              </div>
              <div class="tip-text">无需苹果账户</div>
            </div>

            <div class="download-item">
              <div class="download-btn app-store-btn" @click="handleDownload('ios')">
                <div class="icon">
                  <font-awesome-icon :icon="['fab', 'apple']" />
                </div>
                <div class="text-container">
                  <div class="small-text">立即下载</div>
                  <div class="large-text">App Store</div>
                </div>
              </div>
              <div class="tip-text">上架中,咨询客服获取备用下载</div>
            </div>

          </div>
        </div>
      </section>

      <!-- 页脚 -->
      <footer class="footer">
        <div class="footer-content">
          <div class="footer-logo">
            <img src="../assets/vpn/logo.png" alt="logo" width="30" />
            <span>Brought to you by FlashGo</span>
        </div>
          <div class="store-buttons">
          <img src="../assets/vpn/ios.png" alt="App Store" @click="handleDownload('ios')" />
          <img src="../assets/vpn/an.png" alt="Google Play" @click="handleDownload('android')" />
          <img src="../assets/vpn/win.png" alt="Windows" @click="handleDownload('windows')" />
          <img src="../assets/vpn/mac.png" alt="macOS" @click="handleDownload('macos')" />
        </div>
        </div>
      </footer>
    </main>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '../plugins/fontawesome'
import axios from 'axios'

export default {
  name: 'HomeIndex',
  components: {
    FontAwesomeIcon
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      showAlert: false,
      alertMessage: '',
      displayMs: 200,
      displayNodes: 1,
      displayIPs: 1,
      displaySpeed: 100,
      downloadLinks: {
        windows: 'https://47.119.177.118/client/FlashGo_latest.exe',
        macos: 'https://47.119.177.118/client/FlashGo_latest.dmg',
        android: 'https://47.119.177.118/client/FlashGo_latest.apk',
        ios: 'https://47.119.177.118/client/FlashGo_latest.ipa'
      },
      // 新增IP封禁相关数据
      isBlocked: false,
      userIP: '',
      userRegion: '',
      blockedRegions: ['shandong', 'hunan'],
      isLoading: true
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
    this.startMsAnimation()
    this.startNodesAnimation()
    this.startIPsAnimation()
    this.startSpeedAnimation()
    // 添加IP检测
    this.checkUserIP()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth
    },
    handleDownload(platform) {
      const link = this.downloadLinks[platform]
      if (platform === 'ios') {
        // iOS 显示自定义提示框
        this.alertMessage = 'App Store 正在上架中! 咨询客服获取备用下载方案'
        this.showAlert = true
        // 3秒后自动关闭
        setTimeout(() => {
          this.showAlert = false
        }, 5000)
        return
      } else {
        // 其他平台直接下载
        const a = document.createElement('a')
        a.href = link
        a.download = `FlashGo_latest${platform === 'windows' ? '.exe' : platform === 'macos' ? '.dmg' : '.apk'}`
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }
    },
    // 使用GeoJS检查用户IP
    async checkUserIP() {
      try {
        // 使用GeoJS API获取IP信息
        const response = await axios.get('https://get.geojs.io/v1/ip/geo.json')

        // 保存用户IP和地区信息
        this.userIP = response.data.ip
        this.userRegion = response.data.region

        // 检查是否在中国
        if (response.data.country_code === 'CN') {
          // 检查是否在山东省，先转为小写再比较
          const userRegionLower = response.data.region.toLowerCase()
          if (this.blockedRegions.some(region =>
              userRegionLower === region.toLowerCase() ||
              userRegionLower.includes(region.toLowerCase()))) {
            this.isBlocked = true
          }
        }

        console.log('IP检测结果:', response.data)
      } catch (error) {
        console.error('IP检测失败:', error)
        // 如果IP检测失败，可以选择默认放行
      } finally {
        this.isLoading = false
      }
    },
    startMsAnimation() {
      const startMs = 300
      const endMs = 10
      const duration = 5000 // 5秒动画

      // 创建缓动函数
      const easeOutExpo = t => {
        return t === 1 ? 1 : 1 - Math.pow(2, -8 * t)
      }

      // 颜色渐变函数
      const getColor = (value) => {
        // 将数值范围映射到0-1之间
        const progress = (value - endMs) / (startMs - endMs)

        // 红-黄-绿渐变
        let r, g, b
        if (progress >= 0.5) {
          // 从红色到黄色 (第一阶段)
          const p = (progress - 0.5) * 2 // 将0.5-1映射到0-1
          r = 255
          g = Math.round(255 * (1 - p))
          b = 0
        } else {
          // 从黄色到绿色 (第二阶段)
          const p = progress * 2 // 将0-0.5映射到0-1
          r = Math.round(255 * p)
          g = 255
          b = 0
        }

        return `rgb(${r}, ${g}, ${b})`
      }

      let startTime = null

      const animate = (currentTime) => {
        if (!startTime) startTime = currentTime
        const elapsed = currentTime - startTime

        if (elapsed < duration) {
          const progress = elapsed / duration
          const easedProgress = easeOutExpo(progress)

          let currentValue = startMs - (startMs - endMs) * easedProgress
          if (currentValue < 100) {
            const slowdownFactor = Math.pow((currentValue - endMs) / (100 - endMs), 1.8)
            currentValue = endMs + (currentValue - endMs) * slowdownFactor
          }

          // 确保数值为整数
          this.displayMs = Math.max(Math.round(currentValue), endMs)

          // 更新数字颜色
          const numberElement = document.querySelector('.ms-number')
          if (numberElement) {
            const color = getColor(this.displayMs)
            numberElement.style.color = color
            numberElement.style.webkitTextFillColor = 'initial'
          }

          requestAnimationFrame(animate)
        } else {
          this.displayMs = endMs
          // 设置最终颜色
          const numberElement = document.querySelector('.ms-number')
          if (numberElement) {
            numberElement.style.color = '#00ff95'
            numberElement.style.webkitTextFillColor = 'initial'
          }
        }
      }

      requestAnimationFrame(animate)
    },
    startNodesAnimation() {
      const startNodes = 1
      const endNodes = 90
      const duration = 3000 // 3秒动画

      const easeOutExpo = t => {
        return t === 1 ? 1 : 1 - Math.pow(2, -8 * t)
      }

      let startTime = null

      const animate = (currentTime) => {
        if (!startTime) startTime = currentTime
        const elapsed = currentTime - startTime

        if (elapsed < duration) {
          const progress = elapsed / duration
          const easedProgress = easeOutExpo(progress)

          this.displayNodes = Math.round(startNodes + (endNodes - startNodes) * easedProgress)
          requestAnimationFrame(animate)
        } else {
          this.displayNodes = endNodes
        }
      }

      requestAnimationFrame(animate)
    },
    startIPsAnimation() {
      const startIPs = 1
      const endIPs = 100
      const duration = 3000 // 3秒动画

      const easeOutExpo = t => {
        return t === 1 ? 1 : 1 - Math.pow(2, -8 * t)
      }

      let startTime = null

      const animate = (currentTime) => {
        if (!startTime) startTime = currentTime
        const elapsed = currentTime - startTime

        if (elapsed < duration) {
          const progress = elapsed / duration
          const easedProgress = easeOutExpo(progress)

          this.displayIPs = Math.round(startIPs + (endIPs - startIPs) * easedProgress)
          requestAnimationFrame(animate)
        } else {
          this.displayIPs = endIPs
        }
      }

      requestAnimationFrame(animate)
    },
    startSpeedAnimation() {
      const startSpeed = 100
      const endSpeed = 4000
      const duration = 3000 // 3秒动画

      const easeOutExpo = t => {
        return t === 1 ? 1 : 1 - Math.pow(2, -8 * t)
      }

      let startTime = null

      const animate = (currentTime) => {
        if (!startTime) startTime = currentTime
        const elapsed = currentTime - startTime

        if (elapsed < duration) {
          const progress = elapsed / duration
          const easedProgress = easeOutExpo(progress)

          this.displaySpeed = Math.round(startSpeed + (endSpeed - startSpeed) * easedProgress)
          requestAnimationFrame(animate)
        } else {
          this.displaySpeed = endSpeed
        }
      }

      requestAnimationFrame(animate)
    },
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  min-height: 100vh;
  background: #000;
  color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
}

.nav-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  height: 70px;
  background: rgba(0, 0, 0, 0.95);
  backdrop-filter: blur(10px);

  .nav-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
      display: flex;
      align-items: center;
      gap: 12px;

      span {
        font-size: 24px;
        font-weight: bold;
        background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-size: 400%;
        animation: flow 20s linear infinite;
      }
    }

    .nav-right {
      .store-buttons {
        display: flex;
        gap: 16px;

        img {
          height: 36px;
          cursor: pointer;
          transition: transform 0.2s;

          &:hover {
            transform: scale(1.05);
          }
        }
      }
    }
  }
}

.main-content {
  padding-top: 70px;
}

.hero-section {
  min-height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  padding: 120px 20px 40px;
  background: radial-gradient(circle at center, #1a1a1a 0%, #000 100%);

  h1 {
    font-size: 48px;
    line-height: 1.2;
    margin-bottom: 8px;
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 400%;
    animation: flow 20s linear infinite;
  }

  .subtitle {
    font-size: 20px;
    margin-bottom: 16px;
    font-weight: 500;
    letter-spacing: 1px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 16px;
    flex-wrap: nowrap;

    .info-line {
      display: inline-flex;
      align-items: center;
      white-space: nowrap;
    }

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 12px;
      margin-bottom: 24px;

      .info-line {
        font-size: 16px;
        justify-content: center;
        width: 100%;
      }
    }

    .ms-number {
      display: inline-block;
      min-width: 3ch;
      font-size: 32px;
      font-weight: 700;
      text-align: center;
      color: #ff4444;
      background: none;
      -webkit-background-clip: initial;
      -webkit-text-fill-color: initial;
      transition: color 0.1s ease;
      vertical-align: baseline;
      margin: 0 2px;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: -8px;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        height: 2px;
        background: linear-gradient(90deg, transparent, #00ffd5, transparent);
      }
    }

    .speed-number {
      display: inline-block;
      min-width: 2ch;
      font-size: 32px;
      font-weight: 700;
      text-align: center;
      color: #00ff95;
      background: none;
      -webkit-background-clip: initial;
      -webkit-text-fill-color: initial;
      vertical-align: baseline;
      margin: 0 10px;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: -8px;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        height: 2px;
        background: linear-gradient(90deg, transparent, #00ffd5, transparent);
      }
    }
  }

  .download-options {
    margin-top: 24px;

    .store-buttons {
      display: flex;
      gap: 16px;
      flex-wrap: wrap;
      justify-content: center;

      .download-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;

        .download-btn {
          height: 56px;
          padding: 0 20px;
          border-radius: 8px;
          display: flex;
          align-items: center;
          gap: 10px;
          cursor: pointer;
          transition: all 0.2s;
          background: rgba(255, 255, 255, 0.1);
          border: 1px solid rgba(255, 255, 255, 0.2);
          color: white;
          font-size: 16px;
          text-decoration: none;
          min-width: 150px;

          .icon {
            font-size: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .text-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .small-text {
              font-size: 12px;
              opacity: 0.8;
            }

            .large-text {
              font-size: 15px;
              font-weight: 500;
            }
          }

          &:hover {
            background: rgba(255, 255, 255, 0.15);
            transform: translateY(-2px);
            border-color: rgba(255, 255, 255, 0.3);
          }

          &.app-store-btn {
            background: linear-gradient(45deg, #1a73e8, #174ea6);
            border: none;

            &:hover {
              background: linear-gradient(45deg, #1557b0, #1a73e8);
            }
          }

          &.play-store-btn {
            background: linear-gradient(45deg, #00c853, #009624);
            border: none;

            &:hover {
              background: linear-gradient(45deg, #00e676, #00c853);
            }
          }

          &.windows-btn {
            background: linear-gradient(45deg, #4f0d95, #452296);
            border: none;

            &:hover {
              background: linear-gradient(45deg, #5a0fb0, #4d27aa);
            }
          }

          &.macos-btn {
            background: linear-gradient(45deg, #4f0d95, #452296);
            border: none;

            &:hover {
              background: linear-gradient(45deg, #5a0fb0, #4d27aa);
            }
          }
        }

        .tip-text {
          font-size: 12px;
          color: rgba(255, 255, 255, 0.6);
          text-align: center;
          margin-top: 4px;
        }
      }
    }
  }
}

.footer {
  padding: 60px 20px;
  background: #000;

  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;

    .footer-logo {
      display: flex;
      align-items: center;
      gap: 16px;

      span {
        font-size: 20px;
        color: #888;
      }
    }

    .store-buttons {
      display: flex;
      gap: 16px;
      flex-wrap: wrap;
      justify-content: center;

      img {
        height: 48px;
        cursor: pointer;
        transition: transform 0.2s;

        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }
}

@keyframes flow {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

@media (max-width: 768px) {
  .nav-bar {
    height: 60px;

    .nav-content {
      .logo span {
        font-size: 20px;
      }

      .store-buttons img {
        height: 32px;
      }
    }
  }

  .main-content {
    padding-top: 60px;
  }

  .hero-section {
    min-height: calc(100vh - 60px);
    padding: 10px 10px 10px;

    h1 {
      font-size: 32px;
      margin-bottom: 4px;
    }

    .subtitle {
      font-size: 18px;
      margin-bottom: 12px;

      .ms-number {
        font-size: 22px;

        &::after {
          bottom: -6px;
          width: 100%;
        }
      }
    }

    .download-options {
      margin-top: 12px;
    }

    .download-options .store-buttons img {
      height: 40px;
    }
  }

  .footer {
    padding: 16px;

    .footer-content {
      gap: 12px;

      .store-buttons img {
        height: 32px;
      }
    }
  }
}

/* 平板设备适配 */
@media (min-width: 768px) and (max-width: 1024px) {
  .hero-section {
    .download-options {
      width: 100%;
      padding: 0 20px;

      .store-buttons {
        width: 100%;
        max-width: 700px;
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 24px;

        .download-item {
          width: 100%;

          .download-btn {
            width: 100%;
            justify-content: center;
            padding: 0 16px;
            min-width: 180px;
            max-width: 280px;
            margin: 0 auto;
          }
        }
      }
    }
  }
}

/* 自定义提示框样式 */
.custom-alert {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  min-width: 300px;
  max-width: 90%;
}

.alert-content {
  background: rgba(0, 0, 0, 0.95);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  padding: 20px 28px;
  display: flex;
  align-items: center;
  gap: 16px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.4);
}

.alert-icon {
  font-size: 24px;
  color: #1a73e8;
}

.alert-message {
  color: #ffffff;
  font-size: 16px;
  flex-grow: 1;
}

.alert-close {
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  padding: 4px;
  font-size: 18px;
  transition: color 0.2s;

  &:hover {
    color: #ffffff;
  }
}

/* 提示框动画 */
.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translate(-50%, calc(-50% + 20px));
}

@keyframes shimmer {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}

.nodes-number {
  display: inline-block;
  min-width: 3ch;
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  color: #00ff95;
  background: none;
  -webkit-background-clip: initial;
  -webkit-text-fill-color: initial;
  vertical-align: baseline;
  margin: 0 2px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, #00ffd5, transparent);
  }
}

@media (max-width: 768px) {
  .nodes-number {
    font-size: 22px;

    &::after {
      bottom: -6px;
    }
  }
}

.ips-number {
  display: inline-block;
  min-width: 3ch;
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  color: #00ff95;
  background: none;
  -webkit-background-clip: initial;
  -webkit-text-fill-color: initial;
  vertical-align: baseline;
  margin: 0 10px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, #00ffd5, transparent);
  }
}

@media (max-width: 768px) {
  .ips-number {
    font-size: 22px;

    &::after {
      bottom: -6px;
    }
  }
}

/* IP封禁样式 */
.ip-block-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.95);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(10px);
}

.ip-block-content {
  background: #111;
  border-radius: 16px;
  padding: 40px;
  max-width: 500px;
  width: 90%;
  text-align: center;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.1);

  h2 {
    font-size: 28px;
    margin-bottom: 16px;
    color: #ff3b30;
  }

  p {
    font-size: 16px;
    margin-bottom: 12px;
    color: #fff;
    line-height: 1.5;
  }

  .ip-info {
    margin-top: 24px;
    padding-top: 16px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    font-size: 14px;
    color: rgba(255, 255, 255, 0.6);
  }
}

.block-icon {
  font-size: 64px;
  color: #ff3b30;
  margin-bottom: 24px;
}

@media (max-width: 768px) {
  .ip-block-content {
    padding: 30px 20px;

    h2 {
      font-size: 24px;
    }

    .block-icon {
      font-size: 48px;
      margin-bottom: 16px;
    }
  }
}
</style>
